import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import SectionHeading from "../components/SectionHeading";
import { backGround } from "../layout/customStyle";
import { Grid } from "@material-ui/core";

import {
  ArrowBack,
  ArrowForward,
  Event as EventIcon,
} from "@material-ui/icons";
import {
  makeStyles,
  IconButton,
  Typography,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";
import Clock from "../components/Clock";
import "../assets/css/upcoming.css";
import { events } from "../assets/js/event";
import NewCard from "../components/New_EventCard";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "../assets/content/intel.pdf";
import CodeQuest4_Winners from "../assets/img/gallery/CodeQuest4/CodeQuest4.0_Winners.jpeg"
import Confetti from 'react-confetti';


const onDownload = (linkName, title) => {
  let a = document.createElement("a");
  a.href = linkName;
  // console.log(linkName);
  a.setAttribute("download", title);
  a.click();
};





const Downloadables = ({ label, description, downloadable, name }) => {
  return (
    <div style={{ margin: "10px" }}>
      {label}:
      <Button
        variant="contained"
        color="primary"
        style={{
          padding: "1px 4px 1px 4px",
          marginLeft: "6px",
        }}
        onClick={() => onDownload(downloadable, name)}
      >
        {description}
      </Button>
    </div>
  );
};

const Links = ({ label, description, link }) => {
  return (
    <div style={{ margin: "10px" }}>
      {label}:
      <Button
        variant="contained"
        color="primary"
        style={{
          padding: "1px 4px 1px 4px",
          marginLeft: "6px",
        }}
        onClick={() => window.open(link)}
      >
        {description}
      </Button>
    </div>
  );
};

const detailsType = {
  downloadable: (upComingEvent) => Downloadables(upComingEvent),
  link: (upComingEvent) => Links(upComingEvent),
};

const yearList = ["2023-24","2022-23", "2021-22", "2020-21", "2019-20", "2018-19"];

class EventPage extends Component {
  state = {
    yearIndex: 0,
    filteredEvents: [],
  };

  filterEvents = (year) => {
    let filteredEvents = [];
    Object.keys(events).forEach((event) => {
      if (events[event][0].year === year) filteredEvents.push(events[event][0]);
    });
    this.setState({ filteredEvents });
  };

  componentDidMount() {
    const year = this.props.match.params.year;
    const yearFound = yearList.find((yr) => yr === year);
    if (yearFound) {
      const yearIndex = yearList.indexOf(yearFound);
      this.setState({ yearIndex });
      this.filterEvents(yearList[yearIndex]);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const year = this.props.match.params.year;
    const yearFound = yearList.find((yr) => yr === year);
    if (
      prevProps.match.params.year !== this.props.match.params.year &&
      yearFound
    ) {
      const yearIndex = yearList.indexOf(yearFound);
      this.setState({ yearIndex });
      this.filterEvents(yearList[yearIndex]);
    }
  }

  handleYearIncrease = () => {
    let yearIndex = this.state.yearIndex;

    if (yearIndex === yearList.length - 1) yearIndex = 0;
    else yearIndex++;

    this.filterEvents(yearList[yearIndex]);
    this.setState({ yearIndex });
    this.props.history.push(`/eventPage/${yearList[yearIndex]}`);
  };

  handleYearDecrease = () => {
    let yearIndex = this.state.yearIndex;

    if (yearIndex === 0) yearIndex = yearList.length - 1;
    else yearIndex--;

    this.filterEvents(yearList[yearIndex]);
    this.setState({ yearIndex });
    this.props.history.push(`/eventPage/${yearList[yearIndex]}`);
  };



  render() {
    
   /* const upComingEvents = [
      {
        title: "INSPIRON 2.0 - National Level Hackathon",
        date: "24th - 25th March 2023",
        details: "https://csi.coep.org.in/hackathon-23/#/",
        detail: [
          {
            type: "link",
            label: "Registration Link",
            description: "register",
            link: "https://inspiron23.devfolio.co/",
          },
          {
            type: "link",
            label: "Brochure Link",
            description: "Brochure",
            link: "https://drive.google.com/file/d/1LlU-a8JUYgvQOeXDi3xjwoJ33ucKxDlD/view?usp=sharing",
          },
          {
            type: "link",
            label: "Pitch Deck",
            description: "Download",
            link: "https://docs.google.com/presentation/d/1DLwJ74YDoaaEbosAsZDgRRdKVc1l4pqikVoZ3ns_-fo/edit?usp=sharing",
          },
          {
            type: "link",
            label: "Pitch Deck Submission",
            description: "Submission Form",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSdE1z_ggWmVq8JQIxv_2vKtAPF-8lPC_SBTVSN1sGn9GwchCQ/viewform",
          },
        ],
        poster: require("../assets/img/gallery/Inspiron.png"),
      },
    ];*/
    // const upComingEvents = [];

    const yearFound = yearList.find(
      (yr) => yr === this.props.match.params.year
    );

    if (!yearFound) return <Redirect to={"/eventPage/2023-24"} />;

    

    return (
      <div>
        <main style={backGround}>
          <Navbar event={true} />


          

          <SectionHeading title="PAST EVENTS" />

          {/* 2022 events */}
          <div className="container-fluid">
            <Grid container justify="center" style={{ marginTop: "1rem" }}>
              {
                <Grid item>
                  <IconButton onClick={() => this.handleYearDecrease()}>
                    <ArrowBack
                      style={{
                        color: "white",
                        marginTop: "1rem",
                        marginRight: "1rem",
                      }}
                    />
                  </IconButton>
                </Grid>
              }
              <Grid item>
                <Typography
                  variant="overline"
                  style={{ color: "white", fontSize: "1.75rem" }}
                >
                  AY {yearList[this.state.yearIndex]} Events
                </Typography>
              </Grid>
              {
                <Grid item>
                  <IconButton onClick={() => this.handleYearIncrease()}>
                    <ArrowForward
                      style={{
                        color: "white",
                        marginTop: "1rem",
                        marginLeft: "1rem",
                      }}
                    />
                  </IconButton>
                </Grid>
              }
            </Grid>
          </div>

          <div className="container-sm">
            <Grid className="my-3" container direction="row">
              {this.state.filteredEvents.map((event, index) => {
                return (
                  <Grid className="my-5" container direction="row">
                    <LazyLoadComponent key={`events-${index}`}>
                      <NewCard
                        title={event.title}
                        date={event.date}
                        details={event.details}
                        length={event.details.length}
                        downloadables={event.downloadables}
                        linkedin={event.linkedin}
                        instagram={event.instagram}
                        viewmore={event.viewmore}
                      />
                    </LazyLoadComponent>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div className="mt-5" />
          <Footer />
        </main>
      </div>
    );
  }
}

export default EventPage;
