import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import "./css/teams.css";
import { backGround } from "../layout/customStyle";
import { IconButton, Grid, Typography } from "@material-ui/core";
// import team18 from "../assets/js/teams/team2018";
// import team19 from "../assets/js/teams/team2019";
// import team20 from "../assets/js/teams/team2020";
// import team21 from "../assets/js/teams/team2021";
import Team23 from "../components/Team23.jsx";
import Team22 from "../components/Team22.jsx";
import Team21 from "../components/Team21.jsx";
import Team20 from "../components/Team20.jsx";
import Team19 from "../components/Team19.jsx";
import Team18 from "../components/Team18.jsx";
// import Team from "../components/Team";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

class TeamPage extends Component {
  state = {
    key: 5,
    component: [
      // <Team date="18-19" team={team18} />,
      // <Team date="19-20" team={team19} />,
      // <Team date="20-21" team={team20} />,
      // <Team date="21-22" team={team21} />,
      <Team18 />,
      <Team19 />,
      <Team20 />,
      <Team21 />,
      <Team22 />,
      <Team23/>
    ],
    title: ["AY 2018-19","AY 2019-20", "AY 2020-21", "AY 2021-22", "AY 2022-23","AY 2023-24"],
  };
  handleForwardClick = () => {
    if (this.state.key === this.state.title.length - 1) {
      this.setState({ ...this.state, key: 0 });
    } else {
      this.setState({ ...this.state, key: this.state.key + 1 });
    }
  };
  handleBackwardClick = () => {
    if (this.state.key === 0) {
      this.setState({ ...this.state, key: this.state.title.length - 1 });
    } else {
      this.setState({ ...this.state, key: this.state.key - 1 });
    }
  };
  render() {
    return (
      <main style={backGround}>
        <Navbar team={true} />
        <div className="container-fluid">
          <Grid container justify="center" style={{ marginTop: "1rem" }}>
            <Grid item>
              <IconButton onClick={this.handleBackwardClick}>
                <ArrowBack
                  style={{
                    color: "white",
                    marginTop: "1rem",
                    marginRight: "1rem",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography
                variant="overline"
                style={{ color: "white", fontSize: "1.75rem" }}
              >
                {this.state.title[this.state.key]}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={this.handleForwardClick}>
                <ArrowForward
                  style={{
                    color: "white",
                    marginTop: "1rem",
                    marginLeft: "0.8rem",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </div>

        {this.state.component[this.state.key]}
        <Footer />
      </main>
    );
  }
}

export default TeamPage;
