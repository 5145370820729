import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Fab,
  Zoom,
  useScrollTrigger,
  Popper,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  sectionDesktop: {
    display: "flex",
    fontSize: "1.2rem",
    fontWeight: "400",
    // fontFamily: "Gotham",
    margin: "0 auto",
    padding: "10px 0",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  sectionMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginRight: "-1rem",
    },
  },
  drawer: {
    width: 300,
    backgroundColor: "#080c1d",
    height: "105vh",
  },
  csicoep: {
    // fontFamily: "Bord, sans-serif",
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  navlink: {
    fontSize: "1.1rem",
    // fontFamily: "Nerko One, cursive",
    textDecoration: "none",
    marginRight: theme.spacing(2),
    color: "white",
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  activeNavlink: {
    color: "#4dc7f7",
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.root}
        style={{ zIndex: "10000" }}
      >
        {children}
      </div>
    </Zoom>
  );
}

export default function Navbar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const links = [
    { text: "Home", link: "/", active: props.home },
    // { text: "Hackathon", link: "/hackathon" },
    { text: "Events", link: "/eventPage/2023-24", active: props.event },
    { text: "Testimonials", link: "/testimonials", active: props.testimonial },
    { text: "Recent News", link: "/recentsPage", active: props.recent },
    { text: "Placement", link: "/placement-diaries", active: props.placements },
    { text: "Internships", link: "/ty-diaries", active: props.diary },
    { text: "Team", link: "/teamPage", active: props.team },
  ];

  const diaries = [
    { text: "TY Diaries", link: "/ty-diaries", active: props.TY },
    { text: "BTech Diaries", link: "/btech-diaries", active: props.BTech },
  ];

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleMenuClose();
  };
  const handleMenuClose = () => {
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileDrawer = (
    <Drawer
      anchor="right"
      id={mobileMenuId}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <List className={classes.drawer}>
        <ListItem button onClick={toggleDrawer(false)}>
          <ListItemIcon style={{ flexGrow: 1, justifyContent: "flex-end" }}>
            <CloseIcon style={{ color: "white" }} />
          </ListItemIcon>
        </ListItem>

        {links.map(({ text, link, active }, i) => (
          <div key={i}>
            {text === "Internships" ? (
              <Accordion style={{ backgroundColor: "#080c1d" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "#ffffff" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    className={classes.navlink}
                    style={{
                      color: active ? "#4dc7f7" : "white",
                      textDecoration: "none",
                      fontSize: "1.3rem",
                    }}
                  >
                    Internships
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    {diaries.map(({ text, link, active }, i) => (
                      <Grid item>
                        <Link
                          style={{
                            color: active ? "#4dc7f7" : "white",
                            textDecoration: "none",
                            fontSize: "1.3rem",
                          }}
                          to={link}
                        >
                          <ListItem button>{text}</ListItem>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Link
                style={{
                  color: active ? "#4dc7f7" : "white",
                  textDecoration: "none",
                  fontSize: "1.3rem",
                }}
                to={link}
              >
                <ListItem button>{text}</ListItem>
              </Link>
            )}

            <Divider style={{ backgroundColor: "#fff" }} />
          </div>
        ))}
      </List>
    </Drawer>
  );

  return (
    <div>
      <AppBar style={{ backgroundColor: "#000000" }} elevation={12}>
        <Toolbar>
          <Typography
            variant="h6"
            //fontFamily: "Gotham",
            style={{ flexGrow: 1, fontWeight: 700 }}
          >
            <Link to="/" className={classes.csicoep}>
              {"CSI COEP TECH"}
            </Link>
          </Typography>

          <div className={classes.sectionDesktop}>
            <Link
              to="/"
              className={
                props.home
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
            >
              HOME
            </Link>

             {/* <a href="https://csi.coep.org.in/hackathon/"
            className={
               props.hackathon
                 ? clsx(classes.navlink, classes.activeNavlink)
                 : classes.navlink
             }>
                 HACKATHON
            </a>  */}

            <Link
              to="/eventPage/2023-24"
              className={
                props.event
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
            >
              EVENTS
            </Link>
          
            <Link
              to="/testimonials"
              className={
                props.testimonial
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
            >
              TESTIMONIALS
            </Link>

            <Link
              to="/recentsPage"
              className={
                props.recent
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
            >
              RECENT NEWS
            </Link>

            <Link
              to="/placement-diaries"
              className={
                props.placements
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
            >
              PLACEMENTS
            </Link>

            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => {
                handleToggle();
              }}
              className={
                props.diary
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
              style={{ padding: "0px" }}
            >
              INTERNSHIPS
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper
                    style={{ backgroundColor: "#ffffff30", color: "white" }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow">
                        <MenuItem onClick={handleClose}>
                          <Link
                            to="/ty-diaries"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            TY Diaries
                          </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <Link
                            to="/btech-diaries"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            BTech Diaries
                          </Link>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <Link
              to="/teamPage"
              className={
                props.team
                  ? clsx(classes.navlink, classes.activeNavlink)
                  : classes.navlink
              }
            >
              TEAM
            </Link>
          </div>

          <div className={classes.sectionMobile}>
            <Button
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      {renderMobileDrawer}

      <Toolbar id="back-to-top-anchor" />
      <ScrollTop {...props}>
        <Fab
          className="mr-4"
          color="secondary"
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}
